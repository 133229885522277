
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import type { GetServerSideProps } from 'next';
import type { UriPageProps } from '../client';
import type { AppProps } from '../util';
import { UriPage } from '../client';
import { BroadsideApi } from '../server/broadside-api';
import { CURRENCY_COOKIE, encodeUriPathSegments, parseCookie } from '../util';
import { Logger } from '../server/logger';
import { asCurrency, asLanguage, isLanguage } from '../domain';
import { blockRequest } from '../request-blocking';
const isBadStatus = (status: number): boolean => {
    switch (status) {
        case 200:
        case 404:
            return false;
        default:
            return true;
    }
};
const getServerSideProps: GetServerSideProps<AppProps & UriPageProps> = async ({ params, req, res, locale: language }) => {
    const cloudfrontViewerAddress = req.headers['cloudfront-viewer-address'];
    if (blockRequest(cloudfrontViewerAddress)) {
        console.warn(`IP_BLOCK Denying request from ${cloudfrontViewerAddress} ${req.url}`); // eslint-disable-line no-console
        res.setHeader('cache-control', 'no-cache');
        res.setHeader('content-type', 'text/html');
        res.write('<h1>Too Many Requests</h1><p>You have made too many requests to this website. Try again later.</p>');
        res.end();
        return {} as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
    const uri: string = (() => {
        let uri = `${language}/`;
        if (params) {
            const segments = params.segments;
            if (Array.isArray(segments)) {
                /*
                    Workaround for issue where `segments` & `local` are not properly
                    handled for the home page when a ?query=param is included in the URL.

                    In deployments, we wind up seeing the locale detected as /en, and the
                    /language prefix as part of segments; this means we requests URIs
                    like /en/en-GB from API, which obviously is a 404.

                    So, if we find that we have a single segment which is a language code,
                    we know this should actually have been the homepage for that language.
                */
                const isBadSegments = segments.length === 1 && isLanguage(segments[0]);
                if (isBadSegments) {
                    uri = segments[0];
                }
                else {
                    // The normal behaviour.
                    uri += segments.join('/');
                }
            }
        }
        return encodeUriPathSegments(uri);
    })();
    const cookie = req.headers['cookie'] ?? '';
    const currency = parseCookie(cookie, CURRENCY_COOKIE);
    const api = new BroadsideApi({
        req,
        currency: asCurrency(currency),
        language: asLanguage(language),
    });
    const result = await api.getUri(uri);
    if (result.data?.type === 'redirect') {
        return { redirect: result.data };
    }
    if (result.status !== 200 || result.data?.data?.noCache) {
        res.setHeader('cache-control', 'no-cache');
    }
    if (isBadStatus(result.status)) {
        Logger.badStatus(req, uri, result.status);
    }
    res.statusCode = result.status;
    return { props: { page: result.data, cookie } };
};
export default UriPage;

    async function __Next_Translate__getServerSideProps__1939685a520__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[[...segments]]',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1939685a520__ as getServerSideProps }
  